.card-form-wrapper {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  border: 1px solid rgba(23, 23, 23, 0.08);
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
}

.card-form-backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(23, 23, 23, 0.48);
  border-radius: 4px;
  margin: 25px;
  padding: 30px;
}

.card-form-popup {
  width: 350px;
  height: 480px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  border-radius: 4px;
}

#card-form {
  width: 100%;
  height: 100%;
  padding: 16px 16px 0 16px;
}

.card-form-header {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
}

.card-form-backdrop #card-form #payrails-container-wrapper {
  height: 275px;
}

#payment-button {
  width: 100%;
  margin-top: 32px;
}
