.action-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.actions-wrapper button {
  min-width: 150px;
}

#dropin-container {
  margin: 24px 24px;
}
