.app-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.5);
  margin: 0;
  padding: 0 32px;
  margin-bottom: 16px;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.app-header .header-text {
  display: flex;
  align-items: center;
}

.header-text {
  display: flex;
  text-decoration: none;
  color: black;
  font-size: 24px;
}

.header-text img {
  margin-right: 8px;
}

.header-links {
  display: flex;
  gap: 8px;
  align-items: center;
}

.header-links a {
  color: black;
  font-size: 14px;
  cursor: pointer;
}

.header-links a.active {
  /* color: blue; */
  text-decoration: none;
}
