body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Roboto', sans-serif;
  background-color: #ebf6ff;
  min-height: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h2 {
  text-align: right;
  margin-bottom: 8px;
}

label {
  margin-right: 10px;
  min-width: 250px;
  display: block;

  line-height: 2;
}

input {
  width: 100%;
  line-height: 32px;
  padding: 0 8px;
  font-size: 14px;

  border-radius: 4px;
  border: 1px solid #bfc2c7;
}

h3 {
  margin: 8px;
}

pre {
  font-size: 12px;
  height: 300px;
  overflow-y: scroll;
  padding: 8px;
  border: 1px solid #bfc2c7;
  border-radius: 4px;
  font-family: monospace;
  margin: 0 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
