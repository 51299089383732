#executionId,
#merchantReference {
  width: 400px;
  height: 40px;
}

.info-card-wrapper button {
  margin-left: -52px;
  height: 30px;
  min-width: 46px;
}

.info-blocks-wrapper {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: fit-content;
}
