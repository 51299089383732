.card-component {
  background-color: #fff;
  margin: 24px 24px;
  border-radius: 4px;
  border: 1px solid #bfc2c7;
  padding: 16px;
}

.card-component button,
.card-component select {
  min-width: 100px;
  margin: 5px;
  height: 40px;
  background-color: #fff;
  box-shadow: rgb(60 64 67 / 30%) 0 1px 1px 0, rgb(60 64 67 / 15%) 0 1px 3px 1px;
  border-radius: 4px;
  border: 0;
  padding: 0 8px;
}

.card-component button:hover:not([disabled], .black) {
  background-color: #f9f9f9;
}

.card-component button:active:not([disabled], .black) {
  background-color: #ececec;
}

.card-component .toggleCollapse {
  color: gray;
  text-align: right;
}

.card-component .toggleCollapse span {
  cursor: pointer;
}

.card-component .collapsed {
  display: none;
}
