.configuration-wrapper > div {
  line-height: 1.5;
  margin-bottom: 8px;
}

input[type='checkbox'] {
  width: auto;
}

.checkbox-row {
  display: flex;
}

.checkbox-row label {
  min-width: auto;
}
